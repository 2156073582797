// Copyright 2023 The Casdoor Authors. All Rights Reserved.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//      http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import React from "react";
import {MetaMaskAvatar} from "react-metamask-avatar";

class AccountAvatar extends React.Component {
  render() {
    const {src, size} = this.props;
    // The avatar for Metamask account is directly generated by an algorithm based on the address
    // src = "metamask:0xC304b2cC0Be8E9ce10fF3Afd34820Ed306A23600";
    const matchMetaMask = src.match(/^metamask:(\w+)$/);
    if (matchMetaMask) {
      const address = matchMetaMask[1];
      return (
        <MetaMaskAvatar address={address} size={size} />
      );
    }
    return (
      <img width={size} height={size} src={src} />
    );
  }
}

export default AccountAvatar;
